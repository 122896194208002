@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap);
body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(20, 21, 26);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app {
  font-family: sans-serif;
  text-align: center;
  display: table-cell;
  flex-direction: column;
  align-items: center;

  display: flex;
}

.status-text {
  margin: -32px 0 0 0;
  font-size: 16pt;
  color: rgba(255, 255, 255, 1);
  font-family: "Poppins", sans-serif;
  -webkit-user-select: none;
          user-select: none;
}

.hint-text {
  margin: 8px 0 0 0;
  font-size: 11pt;
  color: rgba(255, 255, 255, 0.6);
  font-family: "Poppins", sans-serif;
  -webkit-user-select: none;
          user-select: none;
  padding: 0 20px 0 20px;
}

.logo {
  position: fixed;
  bottom: 32px;
  width: 200px;
}

